import styled, { css } from 'styled-components'
import {
  BPSizes,
  Colors,
  Palette,
  SiteSpacings,
  StyledH2,
  StyledParagraph,
  getStyledMarginBottom,
} from '../../commons/Theme'

export const StyledSection = styled.div<{
  $showMobile?: boolean
  $hasMarginBottom?: boolean
  $backgroundColor?: string | null
}>`
  display: ${({ $showMobile }) => ($showMobile ? 'block' : 'none')};
  @media screen and (min-width: ${BPSizes.tablet}px) {
    display: block;
  }

  ${({ $hasMarginBottom }) =>
    $hasMarginBottom && getStyledMarginBottom('Large')};

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
      padding-top: 80px;
      padding-bottom: 80px;

      @media (min-width: ${BPSizes.md}px) {
        padding-top: 120px;
        padding-bottom: 120px;
      }

      @media (min-width: ${BPSizes.lg}px) {
        padding-top: 160px;
        padding-bottom: 160px;
      }

      @media (min-width: ${BPSizes.xlg}px) {
      }
    `};
`

export const StyledTitleContainerMain = styled.div<{
  $accordionNext?: boolean
}>`
  margin-bottom: ${SiteSpacings.Spacing06.sm}px;

  @media (min-width: ${BPSizes.tablet}px) {
    margin-bottom: ${SiteSpacings.Spacing07.md}px;
  }

  @media (min-width: ${BPSizes.desktop}px) {
    margin-bottom: ${SiteSpacings.Spacing07.lg}px;
  }

  @media (min-width: ${BPSizes.large}px) {
  }
`

export const StyledTitleContainer = styled.div`
  margin-bottom: ${SiteSpacings.Spacing03.sm}px;
  @media (min-width: ${BPSizes.tablet}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const StyledSectionInner = styled.div``

export const StyledSectionActionBottom = styled.div`
  margin-top: 32px;
  text-align: right;
  @media screen and (min-width: ${BPSizes.tablet}px) {
    margin-top: 64px;
  }
`

export const StyledSectionComponent = styled.div``

export const StyledTitolo = styled(StyledH2)`
  color: ${Palette.Blue['800']};
  line-height: 1.2;
  margin-bottom: 0;
  font-weight: 400;
`

export const StyledSottotitolo = styled.div`
  ${StyledParagraph};
  color: ${Colors.blue800};
  font-weight: 400;
`

export const StyledOffersBadge = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  color: ${Colors.blue800};
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 24.3px;

  @media (min-width: ${BPSizes.tablet}px) {
    justify-content: flex-end;
    font-size: 20px;
    line-height: 27px;
    flex: 0;
    white-space: nowrap;
  }

  @media (min-width: ${BPSizes.desktop}px) {
    font-size: 24px;
    line-height: 32.4px;
  }

  @media (min-width: ${BPSizes.large}px) {
  }
`

export const StyledOffersBadgeIcon = styled.div`
  display: flex;
  align-items: center;
  width: 14.67px;
  margin-left: 13px;

  @media (min-width: ${BPSizes.tablet}px) {
    width: 22px;
  }

  @media (min-width: ${BPSizes.desktop}px) {
  }

  @media (min-width: ${BPSizes.large}px) {
  }

  svg {
    width: 14.67px;

    @media (min-width: ${BPSizes.tablet}px) {
      width: 22px;
    }

    @media (min-width: ${BPSizes.desktop}px) {
    }

    @media (min-width: ${BPSizes.large}px) {
    }
  }
`

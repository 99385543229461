export const FavouriteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.206 22.824L12.029 19.938C12.014 19.93 11.997 19.93 11.982 19.938L6.80696 22.829C5.79896 23.392 4.61896 22.484 4.81096 21.291L5.79696 15.173C5.79996 15.156 5.79396 15.138 5.78196 15.126L1.37096 10.566C0.644965 9.81601 1.04496 8.50801 2.04696 8.35301L8.13997 7.41001C8.15597 7.40701 8.17097 7.39601 8.17797 7.38101L10.763 1.81401C11.267 0.729006 12.724 0.728006 13.229 1.81301L15.82 7.37801C15.827 7.39401 15.842 7.40501 15.858 7.40701L21.952 8.34401C22.955 8.49801 23.356 9.80601 22.63 10.557L18.223 15.121C18.211 15.133 18.206 15.151 18.209 15.168L19.2 21.285C19.393 22.477 18.215 23.386 17.206 22.824V22.824Z"
        stroke="#013A68"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import { ContainerFluid } from 'commons/Container'
import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { normalizeString } from '../../commons/utils'
import {
  StyledOffersBadge,
  StyledOffersBadgeIcon,
  StyledSection,
  StyledSectionActionBottom,
  StyledSectionComponent,
  StyledSectionInner,
  StyledSottotitolo,
  StyledTitleContainer,
  StyledTitleContainerMain,
  StyledTitolo,
} from './Section.style'
import { SectionProps } from './Section.types'
import { FavouriteIcon } from '../Icons/FavouriteIcon'

const Section: FC<PropsWithChildren<SectionProps>> = ({
  titolo,
  sottotitolo,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  titoloRientrato = false,
  showOffersBadge,
  backgroundColor,
  style,
  components,
  className,
  hasMarginBottom,
  hasContainer = true,
  noPaddingContainer,
  showMobile = true,
  children,
  actionBottom,
}) => {
  const firstComponentAccordion =
    (components &&
      components.length &&
      components[0]?.props?.component === 'paragraph__accordion_fe') ||
    (components &&
      components.length &&
      components[0]?.type.displayName === 'AccordionStandalone')

  const SectionInnerComponent = (
    <>
      {(titolo || sottotitolo) && (
        <StyledTitleContainerMain
          $accordionNext={firstComponentAccordion === true}
        >
          {titolo !== undefined && (
            <StyledTitleContainer id={normalizeString(titolo)}>
              <StyledTitolo className="a2a-section-title-nm5tn">
                {titolo}
              </StyledTitolo>

              {showOffersBadge === true && (
                <StyledOffersBadge>
                  In base ai tuoi interessi{' '}
                  <StyledOffersBadgeIcon>
                    <FavouriteIcon />
                  </StyledOffersBadgeIcon>
                </StyledOffersBadge>
              )}
            </StyledTitleContainer>
          )}

          {sottotitolo && <StyledSottotitolo>{sottotitolo}</StyledSottotitolo>}
        </StyledTitleContainerMain>
      )}

      <StyledSectionInner className="a2afe-section-inner">
        {components &&
          components.map((component, i) => (
            <StyledSectionComponent key={i}>{component}</StyledSectionComponent>
          ))}
        {children}
      </StyledSectionInner>

      {actionBottom && (
        <StyledSectionActionBottom>{actionBottom}</StyledSectionActionBottom>
      )}
    </>
  )

  return (
    <StyledSection
      style={style}
      className={classNames('a2a-section-nm5tn', className)}
      $showMobile={showMobile}
      $hasMarginBottom={hasMarginBottom}
      $backgroundColor={backgroundColor}
    >
      {hasContainer ? (
        <ContainerFluid
          className={classNames({ 'no-padding': noPaddingContainer })}
        >
          {SectionInnerComponent}
        </ContainerFluid>
      ) : (
        <>{SectionInnerComponent}</>
      )}
    </StyledSection>
  )
}

export default Section
